<template>
  <div class="orderInfo">
    <t_header :isUserInfo="isUserInfo" @setTodos="getTodos"></t_header>
    <div class="orderInfo_main">
      <div class="orderInfo_main_header">
        订单信息
      </div>
      <div class="orderInfo_main_address" v-if="bayType=='1' && isTextbook ==1">
        <div class="orderInfo_main_address_header">
          <span>收货地址</span>
          <button @click="addressDialogVisible=true" style="cursor:pointer;">添加地址</button>
        </div>
        <div class="orderInfo_main_address_add" v-if="JSON.stringify(orderAddress)=='{}' || orderAddress===null || orderAddress==undefined">
          <!-- <div class=""> -->
          <span>当前没有收获地址哦～，请</span><span @click="addressDialogVisible=true" style="cursor:pointer;">添加地址</span>
          <!-- </div> -->
        </div>
        <div class="orderInfo_main_address_view" v-else @click="addressListDialogVisible=true">
          <img src="@/assets/course/order_site.png" alt="" class="orderInfo_main_address_view_icon">
          <div class="orderInfo_main_address_view_content">
            <div class="orderInfo_main_address_view_content_left">
              <p>
                <span>{{orderAddress.name}}</span>
                <span>{{orderAddress.mobile}}</span>
              </p>
              <p>{{orderAddress.province}}{{orderAddress.city}}{{orderAddress.area}}{{orderAddress.address}}</p>
            </div>
            <div class="orderInfo_main_address_view_content_right">
              <img src="@/assets/course/order_arrow.png" alt="">
            </div>
          </div>
        </div>
      </div>

      <div class="orderInfo_main_info" v-if="bayType=='1'">
        <div class="info_header" style="font-size:20px;">套餐名称</div>
        <div class="info_title">{{orderCoursePackageInfo.coursePackageName}}</div>
      </div>
      <div class="orderInfo_main_info" v-else>
        <div class="info_header">直播</div>
        <div class="info_title">{{liveOrderDetail.name}}</div>
      </div>
      <div class="orderInfo_main_infoBox" v-if="bayType=='1'">
        <div class="orderInfo_main_infoBox_header">
          <span>赠送课程</span>
          <!-- <span>订单价格</span> -->
        </div>
        <div class="orderInfo_main_infoBox_content" v-for="(item,index) in orderCoursePackageInfo.giveCourseNames" :key="index">
          <div class="orderInfo_main_infoBox_content_left">
            <p class="orderInfo_main_infoBox_content_left_title">{{item}}</p>
            <!-- <p class="orderInfo_main_infoBox_content_left_largess" v-for="(item,index) in orderCoursePackageInfo.giveCourseNames" :key="index">
                            <span>赠品：</span><span>{{item}}</span>
                        </p> -->
          </div>
          <!-- <div class="orderInfo_main_infoBox_content_right">
                        <span>¥</span>
                        <span>{{orderCoursePackageInfo.price}}</span>
                    </div> -->
        </div>
      </div>
      <div class="orderInfo_main_infoBox" v-else>
        <div class="orderInfo_main_infoBox_header">
          <span>名称</span>
          <span>订单价格</span>
        </div>
        <div class="orderInfo_main_infoBox_content">
          <div class="orderInfo_main_infoBox_content_left">
            <p class="orderInfo_main_infoBox_content_left_title">{{liveOrderDetail.name}}</p>
          </div>
          <div class="orderInfo_main_infoBox_content_right">
            <span>¥</span>
            <span>{{liveOrderDetail.price}}</span>
          </div>
        </div>
      </div>
      <div class="orderInfo_main_coupon" v-if="couponList.length>0">
        <div class="orderInfo_main_coupon_title">优惠券</div>
        <div class="orderInfo_main_coupon_list">
          <div class="orderInfo_main_coupon_list_item" v-for="(item,index) in couponList" :key="index" @click="tabCoupon(couponList[index])">
            <img src="@/assets/course/coupon_checked.png" alt="" class="checked" v-if="couponIndex==item.courseCouponId">
            <div class="orderInfo_main_coupon_list_item_box">
              <img src="@/assets/course/order_coupon.png" alt="" class="banner">
              <div class="orderInfo_main_coupon_list_item_box_price">
                <p>
                  <span>{{item.price}}</span><span>元</span>
                </p>
                <p>满{{item.enablePrice}}使用</p>
              </div>
            </div>
          </div>
          <!-- <div class="orderInfo_main_coupon_list_item" 
                        v-for="(item,index) in classDetail.courseCouponList" :key="index" @click="tabCoupon(classDetail.courseCouponList[index])">
                        <img src="@/assets/course/coupon_checked.png" alt="" class="checked" v-if="couponIndex==item.courseCouponId">
                        <div class="orderInfo_main_coupon_list_item_box" >
                            <img src="@/assets/course/order_coupon.png" alt="" class="banner" >
                            <div class="orderInfo_main_coupon_list_item_box_price">
                                <p>
                                    <span>{{item.price}}</span><span>元</span>
                                </p>
                                <p>满{{item.enablePrice}}使用</p>
                            </div>
                        </div>
                    </div> -->

        </div>
      </div>
      <div class="orderInfo_main_payType">
        <div class="orderInfo_main_payType_title">支付方式</div>
        <div class="orderInfo_main_payType_list">
          <div class="orderInfo_main_payType_list_item" v-for="(item,index) in payTypeList" :key="index" @click="tabPayType(item)">
            <img src="@/assets/course/coupon_checked.png" alt="" class="checked" v-if="payTypeIndex==item.id">
            <img src="@/assets/course/Alipay.png" alt="" style="width:22px;height:22px;" v-if="item.id=='1'">
            <img src="@/assets/course/WX.png" alt="" style="width:22px;height:22px;" v-if="item.id=='2'">
            <img src="@/assets/course/JD.png" alt="" style="width:22px;height:22px;" v-if="item.id=='3'">
            <img src="@/assets/course/E-bank.png" alt="" style="width:28px;height:18px;" v-if="item.id=='4'">
            <span>{{item.name}}</span>
          </div>
        </div>
        <div class="orderInfo_main_payType_notice">
          <!-- 注：支持花呗分期 -->
        </div>
      </div>
      <div class="orderInfo_main_priceInfo" v-if="bayType ==1">
        <div class="orderInfo_main_priceInfo_item">
          <p>
            <span>总金额：</span>
          </p>
          <p>
            <span>¥</span><span>{{allPrice}}</span>
          </p>
        </div>
        <div class="orderInfo_main_priceInfo_item">
          <p>
            <span>抵扣：</span>
            <span>当前积分{{totalScore}}，可抵扣{{pointDeductionPrice}}元</span>
          </p>
          <p>
            <el-checkbox v-model="checkedDeduction" @change="deduction">¥{{pointDeductionPrice}}</el-checkbox>
          </p>
        </div>
        <div class="orderInfo_main_priceInfo_item">
          <p>
            <span>优惠：</span>
          </p>
          <p>
            <span>¥</span>
            <span v-if="couponObj !=null">{{couponObj.price}}</span>
            <span v-else>0</span>
          </p>
        </div>
        <div class="orderInfo_main_priceInfo_item">
          <p>应付金额：</p>
          <p>
            <span>¥</span><span>{{amountPayable}}</span>
          </p>
        </div>
      </div>
      <div class="orderInfo_main_priceInfo" v-if="bayType ==2">
        <div class="orderInfo_main_priceInfo_item">
          <p>
            <span>总金额：</span>
          </p>
          <p>
            <span>¥</span><span>{{allPrice}}</span>
          </p>
        </div>
        <div class="orderInfo_main_priceInfo_item">
          <p>
            <span>抵扣：</span>
            <span>当前积分{{totalScore}}，可抵扣{{pointDeductionPrice}}元</span>
          </p>
          <p>
            <el-checkbox v-model="checkedDeduction" @change="deduction">¥{{pointDeductionPrice}}</el-checkbox>
          </p>
        </div>
        <div class="orderInfo_main_priceInfo_item">
          <p>
            <span>优惠：</span>
          </p>
          <p>
            <span>¥</span>
            <span v-if="couponObj !=null">{{couponObj.price}}</span>
            <span v-else>0</span>
          </p>
        </div>
        <div class="orderInfo_main_priceInfo_item">
          <p>应付金额：</p>
          <p>
            <span>¥</span><span>{{amountPayable}}</span>
          </p>
        </div>
      </div>

      <div class="orderInfo_main_other">
        <div style="display:flex;aligin-items:center;justify-content:flex-start;color: rgb(0, 122, 255);">
          <el-checkbox :value="checkedRead" @change="checkedChange">已阅读并同意</el-checkbox>
          <span @click="goumaixieyi" style="cursor:pointer;font-size: 14px;">{{orderCoursePackageInfo.coursePurchaseAgreementName}}</span>
        </div>

        <el-button type="primary" @click="goPay">立即支付</el-button>
        <!-- :disabled="!checkedRead" -->
      </div>
    </div>
    <!-- 付钱弹窗 -->
    <div class="dialog">
      <el-dialog title="微信扫码支付" :visible.sync="centerDialogVisible" width="570px" center>
        <!-- <p class="dialog_between"><span>课程名称：</span><span>{{courseMap.name}}</span></p>
                <p class="dialog_between"><span>应付金额：</span><span class="color_red">¥{{courseMap.totalPrices}}</span></p> -->
        <p class="line"></p>
        <p class="dialog_between" style="text-align:center;"><span>微信扫码</span></p>
        <div id="qrcode" ref='qrcode' class="dialog_center dialog_img" style="width: 100%;display: flex;justify-content: center;"></div>
        <p class="dialog_center" style="text-align:center;">请使用微信扫码，支付成功后自动开通服务</p>
        <!-- <span slot="footer" class="dialog-footer">
                    <el-button @click="tonext"> 我已完成支付 </el-button>
                </span> -->
      </el-dialog>
    </div>
    <div class="popup_address">
      <el-dialog title="添加地址" :visible.sync="addressDialogVisible" width="800px" center>
        <el-form ref="form" :model="form" label-width="80px" class="demo-ruleForm">
          <el-form-item label="所在地区">
            <el-select v-model="form.province" placeholder="请选择省" @change="proviceChange">
              <el-option v-for="(item,index) in provinceList" :key="index" :label="item.name" :value="item.provinceId"></el-option>
            </el-select>
            <el-select v-model="form.city" placeholder="请选择市" @change="cityChange">
              <el-option v-for="(item,index) in cityList" :key="index" :label="item.name" :value="item.cityId"></el-option>
            </el-select>
            <el-select v-model="form.area" placeholder="请选择区" @change="areaChange">
              <el-option v-for="(item,index) in areaList" :key="index" :label="item.name" :value="item.areaId"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="收货人">
            <el-input v-model="form.name" placeholder="收货人姓名"></el-input>
          </el-form-item>
          <el-form-item label="手机号">
            <el-input v-model="form.mobile" maxlength='11' placeholder="手机号"></el-input>
          </el-form-item>
          <el-form-item label="详细地址">
            <el-input v-model="form.site" placeholder="请输入详细地址"></el-input>
          </el-form-item>
          <el-form-item label="是否默认">
            <el-switch v-model="form.delivery"></el-switch>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="addAdress">添加</el-button>
          </el-form-item>
        </el-form>
      </el-dialog>

      <el-dialog title="选择地址" :visible.sync="addressListDialogVisible" width="800px" center>
        <div class="siteBox" style="height:600px;overflow: auto;">
          <!-- <div class="siteBox_header">
                        <el-button type="primary" @click="addAdress_popup()">新增收货地址</el-button>
                        <div class="siteBox_header_explain">
                            <span>您已经创建</span>
                            <span>{{}}</span>
                            <span>个收货地址，最多可创建</span>
                            <span>25</span>
                            <span>个</span>
                        </div>
                    </div> -->
          <div class="siteBox_list">
            <div class="siteBox_list_item" v-for="(item,index) in addressList" :key="index" @click="choice(item)">
              <div class="siteBox_list_item_name">
                <span>收货人：</span>
                <span>{{item.name}}</span>
                <span style="margin-left:20px;">手机：</span>
                <span>{{item.mobile}}</span>
              </div>
              <div class="siteBox_list_item_address">
                <span>地址：</span>
                <span>{{item.city}}{{item.area}}{{item.address}}</span>
              </div>
              <div class="siteBox_list_item_isDefault1" v-if="item.isDefault ==1">
                默认
              </div>
              <div class="siteBox_list_item_position" @click="redact(item,$event)" style="cursor:pointer;color:#007aff;">编辑</div>
            </div>
          </div>
        </div>
      </el-dialog>
    </div>
    <t_footer></t_footer>

    <el-dialog title="提示" :visible.sync="agreementShow" width="60%" :before-close="cancelAgreement">
      <p v-html="agreementText" class="t-c"></p>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancelAgreement">取 消</el-button>
        <el-button :type="agreementTime == 0 ? 'primary' : 'info'" @click="confirmAgreement">确 定 <span v-if="agreementTime > 0">{{agreementTime}}</span></el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import t_header from '@/components/t_header/index.vue'
import t_footer from '@/components/t_footer/index.vue'
import { get_order, gen_liveOrder, course_pay, order_status, live_orderPay } from "@/api/course";
import { coupon_list, point_list, coupon_list2, information_content2 } from "@/api/myself";
import { get_areas, get_citys, get_provices, address_add, address_list } from "@/api/other";

export default {
  name: 'orderInfo',
  components: {
    t_header,
    t_footer,
  },
  watch: {
    $route(to, from) {
      console.log(from.path);//从哪来
      console.log(to.path);//到哪去
    },
    centerDialogVisible(oldvalue, newvalue) {
      console.log(oldvalue)
      console.log(newvalue)
      if (oldvalue == false) {
        this.$refs.qrcode.innerHTML = "";//清空渲染的二维码
      }
    },
    addressListDialogVisible(value) {
      if (value) {
        this.address_list()
      }
    }
  },
  data() {
    return {
      addressDialogVisible: false,// 添加地址
      // 添加地址的数据
      form: {
        province: '',
        city: '',
        area: '',
        name: '',
        mobile: '',
        site: '',
        delivery: false,
      },
      provinceList: [],
      cityList: [],
      areaList: [],
      centerDialogVisible: false,// 二维码支付
      isType: '1',
      isSort: false,
      // todos:false,
      checkedDeduction: false,
      coursePackagePriceId: '',
      coursePackageId: '',
      checkedRead: false,
      agreementShow: false,
      agreementTime: 10,
      agreementText: '',
      countDown: null, //定时器
      orderAddress: {},
      orderCoursePackageInfo: {},
      payTypeList: [
        {
          id: 1,
          name: '支付宝支付'
        }, {
          id: 2,
          name: '微信支付'
        },
        // {
        //   id: 4,
        //   name: '网银支付'
        // }
      ],
      payTypeIndex: 1,
      couponObj: {},
      couponIndex: '',// 选择的优惠券id
      couponList: [],
      amountPayable: 0,// 应付金额
      totalScore: '',
      pointDeductionPrice: '',// 抵扣金额
      totalPrice: '',// 总金额
      proviceName: '',
      cityName: '',
      areaName: '',
      serviceId: '',
      serviceType: 1,
      bayType: 0, // 课程 还是 直播
      liveId: '',
      liveOrderDetail: {},
      addressListDialogVisible: false,// 地址列表弹框是否显示
      addressList: [],
      isUserInfo: false,
      isTextbook: 0,
      classDetail: {},
      allPrice: 0
    }
  },
  mounted() {
    this.bayType = this.$route.query.type

    if (this.bayType == 1) {
      this.classDetail = JSON.parse(this.$route.query.classDetail)
      this.isTextbook = this.$route.query.isTextbook;
      this.coursePackageId = this.$route.query.coursePackageId;
      this.coursePackagePriceId = this.$route.query.coursePackagePriceId
      this.get_order()
      this.address_list();
    } else {
      this.liveId = this.$route.query.liveId
      this.gen_liveOrder()
    }
    this.coupon_list()
    this.point_list()
    this.get_provices()
  },
  methods: {
    //编辑地址
    redact(item, event) {
      console.log(item);
      //area:"120102"
      // city:"120100"
      // delivery:true
      // mobile:"55"
      // name:"44"
      // province:"120000"
      // site:"66"
      this.form.area = item.areaId;
      this.form.city = item.cityId;
      this.form.delivery = item.isDefault;
      this.form.mobile = item.mobile;
      this.form.name = item.name;
      this.form.province = item.provinceId;
      this.form.site = item.address;
      console.log(this.form);
      var obj = {
        provinceId: this.form.province
      }
      this.get_citys(obj);
      var obj2 = {
        cityId: this.form.city
      }
      this.get_areas(obj2);

      this.addressListDialogVisible = false;
      this.addressDialogVisible = true;
      event.stopPropagation();
    },
    // 点击同意协议
    checkedChange() {
      if(this.checkedRead){
        this.checkedRead = false;
      }else{
        this.checkedRead = true;
      }
      // if (!this.checkedRead) {
      //   this.agreementShow = true;
      //   if (this.agreementTime == 10) {
      //     this.countDown = setInterval(() => {
      //       this.agreementTime--;
      //       if (this.agreementTime <= 0) {
      //         clearInterval(this.countDown)
      //         //   this.agreementTime = 0;
      //       }
      //     }, 1000);
      //   }
      // } else {
      //   this.checkedRead = false;
      // }
      //   console.log(this.orderCoursePackageInfo.coursePurchaseAgreementId);
    },
    // 协议弹框 确认
    confirmAgreement() {
      if (this.agreementTime == 0) {
        this.agreementShow = false;
        this.checkedRead = true;
        setTimeout(() => {
          this.agreementTime = 10;
        }, 200);
      }
    },
    // 协议弹框 取消
    cancelAgreement() {
      clearInterval(this.countDown);
      this.agreementShow = false;
      setTimeout(() => {
        this.agreementTime = 10;
      }, 200);

    },
    // 获取协议数据
    information_content2() {
      information_content2({ coursePurchaseAgreementId: this.orderCoursePackageInfo.coursePurchaseAgreementId }).then(res => {
        console.log(res);
        this.agreementText = res.data;
      })
    },
    goumaixieyi() {
      if (this.bayType == 1) {
        let routeData = this.$router.resolve({
          path: '/html', query: { id: this.orderCoursePackageInfo.coursePurchaseAgreementId }
        });
        window.open(routeData.href, '_blank');
      } else if (this.bayType == 2) {
        let routeData = this.$router.resolve({
          path: '/html', query: { id: this.liveOrderDetail.coursePurchaseAgreementId }
        });
        window.open(routeData.href, '_blank');
      }


    },
    // 父传子
    getTodos(e) {
      console.log(e)
      this.isUserInfo = e
    },
    choice(item) {
      console.log(item)
      var obj = this.orderAddress
      obj.address = item.address;
      obj.area = item.area;
      obj.areaId = item.areaId;
      obj.city = item.city;
      obj.cityId = item.cityId;
      obj.mobile = item.mobile;
      obj.name = item.name;
      obj.orderAddressId = item.id;
      obj.province = item.province;
      obj.provinceId = item.provinceId;
      this.orderAddress = obj;
      this.addressListDialogVisible = false;
    },
    // 添加地址
    addAdress() {
      var isDefault = null
      if (this.form.delivery == true) {
        isDefault = 1
      } else {
        isDefault = 0
      }
      var obj = {
        province: this.proviceName,
        provinceId: this.form.province,
        area: this.areaName,
        areaId: this.form.area,
        city: this.cityName,
        cityId: this.form.city,
        address: this.form.site,
        isDefault: isDefault,
        mobile: this.form.mobile,
        name: this.form.name,
      }
      address_add(obj).then(res => {
        console.log(res)
        if (res.data.code == 0) {
          this.addressDialogVisible = false
          this.get_order()
        } else {
          this.$message.error(res.data.msg)
        }
      }).catch(error => {
        console.log(error)
      })
    },
    tonext() {
      this.centerDialogVisible = true
    },
    // 获取省
    get_provices() {
      get_provices().then(res => {
        console.log(res)
        if (res.data.code == 0) {
          this.provinceList = res.data.data
        } else {
          this.$message.error(res.data.msg)
        }
      }).catch(error => {
        console.log(error)
      })
    },
    // 选择省
    proviceChange(value) {
      console.log(value)
      for (var i in this.provinceList) {
        if (value == this.provinceList[i].provinceId) {
          this.proviceName = this.provinceList[i].name
        }
      }
      var obj = {
        provinceId: value
      }
      this.get_citys(obj)
    },
    // 获取市
    get_citys(obj) {
      get_citys(obj).then(res => {
        console.log(res)
        if (res.data.code == 0) {
          this.cityList = res.data.data
        } else {
          this.$message.error(res.data.msg)
        }
      }).catch(error => {
        console.log(error)
      })
    },
    // 市选择
    cityChange(value) {
      for (var i in this.cityList) {
        if (value == this.cityList[i].cityId) {
          this.cityName = this.cityList[i].name
        }
      }
      var obj = {
        cityId: value
      }
      this.get_areas(obj)
    },
    // 获取区
    get_areas(obj) {
      get_areas(obj).then(res => {
        console.log(res)
        if (res.data.code == 0) {
          this.areaList = res.data.data
        } else {
          this.$message.error(res.data.msg)
        }
      }).catch(error => {
        console.log(error)
      })
    },
    // 区选择
    areaChange(value) {
      for (var i in this.areaList) {
        if (value == this.areaList[i].areaId) {
          this.areaName = this.areaList[i].name
        }
      }
    },
    // 地址列表
    address_list() {
      address_list().then(res => {
        console.log(res)
        if (res.data.code == 0) {
          this.addressList = res.data.data
          for (var i in this.addressList) {
            if (this.addressList[i].isDefault == 0) {
              this.addressList[i].isDefault = false
            } else {
              this.addressList[i].isDefault = true
            }
          }
        } else {
          this.$message.error(error)
        }
      }).catch(error => {
        console.log(error)
      })
    },
    // 获取我的优惠券
    coupon_list() {
      var obj = {
        coursePackageId: this.coursePackageId,
        coursePackagePriceId: this.coursePackagePriceId,
        liveId: this.liveId,
      }
      coupon_list2(obj).then(res => {
        console.log(res)
        if (res.data.code == 0) {
          var arr = [];
          for (var i = 0; i < res.data.data.length; i++) {
            if (res.data.data[i].isAvailable == 1) {
              arr.push(res.data.data[i]);
            }
          }
          this.couponList = arr;
        }
      }).catch(error => {
        console.log(error)
      })
    },
    // 获取我的积分
    point_list() {
      point_list().then(res => {
        console.log(res)
        if (res.data.code == 0) {
          this.totalScore = res.data.data.totalPointValue

        }
      }).catch(error => {
        console.log(error)
      })
    },
    // 是否使用抵扣积分
    deduction(val) {
      console.log(val)
      if (val == true) {
        console.log(this.totalPrice)
        console.log(this.pointDeductionPrice)
        if (Number(this.totalPrice) <= Number(this.pointDeductionPrice)) {
          this.amountPayable = 0
        } else {
          this.amountPayable = Number(this.amountPayable) - Number(this.pointDeductionPrice)
        }
      } else {
        this.amountPayable = Number(this.amountPayable) + Number(this.pointDeductionPrice)
      }
    },
    // 切换优惠券
    tabCoupon(item) {
      if (Number(this.totalPrice) < Number(item.enablePrice)) {
        this.$message.error('商品未达到优惠条件');
        return false;
      }

      var tempPrice = 0;
      this.couponObj = item;
      if (this.couponIndex == item.courseCouponId) {
        this.couponIndex = '';
        this.couponObj = null;
      } else {

        for (var i = 0; i < this.couponList.length; i++) {
          if (this.couponIndex == this.couponList[i].courseCouponId) {
            tempPrice = Number(this.couponList[i].price);
          }
        }
        this.couponIndex = item.courseCouponId;

      }
      // 
      if (this.couponIndex == '' || this.couponIndex == null || this.couponIndex == undefined) {
        console.log('1')
        this.amountPayable = Number(this.amountPayable) + Number(item.price);
      } else {
        if (Number(this.totalPrice) >= Number(item.enablePrice)) {
          // var tempPrice = 0;
          // for(var i = 0;i<this.couponList.length;i++){
          //     if(this.couponIndex == this.couponList[i].courseCouponId){
          //         tempPrice = Number(this.couponList[i].price);
          //     }
          // }
          this.amountPayable = Number(this.amountPayable) - Number(item.price) + Number(tempPrice);
        } else {
          this.$message.error('商品未达到优惠条件');
        }
        console.log('2')

      }
    },
    // 切换支付方式
    tabPayType(item) {
      this.payTypeIndex = item.id
    },
    // 获取订单信息
    get_order() {
      var obj = {
        coursePackageId: this.coursePackageId,
        coursePackagePriceId: this.coursePackagePriceId
      }
      get_order(obj).then(res => {
        console.log(res)
        if (res.data.code == 0) {
          this.orderAddress = res.data.data.orderAddress
          this.orderCoursePackageInfo = res.data.data.orderCoursePackageInfo
          this.couponIndex = res.data.data.orderCoursePackageInfo.couponId
          var couponObj = {};
          couponObj.courseCouponId = res.data.data.orderCoursePackageInfo.couponId;
          couponObj.price = res.data.data.orderCoursePackageInfo.couponPrice;
          this.couponObj = couponObj;
          this.allPrice = res.data.data.orderCoursePackageInfo.price

          // if(res.data.data.orderCoursePackageInfo.length>0){
          //     this.couponIndex=res.data.data.orderCoursePackageInfo[0].couponId
          // }
          // if(res.data.data.orderCoursePackageInfo.couponId!='' && res.data.data.orderCoursePackageInfo.couponId!=null && res.data.data.orderCoursePackageInfo.couponId!=undefined){
          //     for(var i in this.couponList){
          //         if(res.data.data.orderCoursePackageInfo.couponId==this.couponList[i].courseCouponId){
          //             this.amountPayable=Number(res.data.data.totalPrice)-Number(this.couponList[i].price)
          //             return
          //         }else{
          //             this.amountPayable=Number(res.data.data.totalPrice)
          //         }
          //     }
          // }else{
          //     this.amountPayable=Number(res.data.data.totalPrice)
          // }
          this.amountPayable = Number(res.data.data.totalPrice)
          this.totalPrice = Number(res.data.data.totalPrice)
          this.pointDeductionPrice = Number(res.data.data.pointDeductionPrice)
          this.information_content2();
        } else {

        }
      }).catch(error => {
        console.log(error)
      })
    },
    // 获取直播订单信息
    gen_liveOrder() {
      var obj = {
        liveId: this.liveId
      }
      gen_liveOrder(obj).then(res => {
        console.log(res)
        if (res.data.code == 0) {
          this.liveOrderDetail = res.data.data
          this.couponIndex = res.data.data.couponId
          var couponObj = {};
          couponObj.courseCouponId = res.data.data.couponId;
          couponObj.price = res.data.data.couponPrice;
          this.couponObj = couponObj;
          this.allPrice = res.data.data.price

          this.amountPayable = Number(res.data.data.totalPrice)
          this.totalPrice = Number(res.data.data.totalPrice)
          this.pointDeductionPrice = Number(res.data.data.integralPrice)
        } else {
          this.$message.error(res.data.msg)
        }
      }).catch(error => {
        console.log(error)
      })
    },
    // 立即购买
    goPay() {
      var that = this
      if (!that.checkedRead) {
        that.$message.error('请阅读并同意购买协议');
        return false
      }
      if (that.bayType == '1') {
        var payWay = ''
        console.log('that.payTypeIndex', that.payTypeIndex)
        if (that.payTypeIndex == '' || that.payTypeIndex == null || that.payTypeIndex == undefined) {
          that.$message.error('请选择支付方式');
          return false
        } else {
          if (that.payTypeIndex == 1) {
            payWay = '支付宝'
          } else if (that.payTypeIndex == 2) {
            payWay = '微信'
          } else if (that.payTypeIndex == 3) {
            payWay = '京东'
          } else if (that.payTypeIndex == 4) {
            payWay = '网银'
          }
        }
        var isPointDeduction = ''
        if (that.checkedDeduction == false) {
          isPointDeduction = 0
        } else {
          isPointDeduction = 1
        }
        var orderAddressId = null;
        if (that.bayType == '1' && that.isTextbook == 1) {
          if (that.orderAddress == null) {
            that.$message.error('请选择收货地址');
            return false
          }
          orderAddressId = that.orderAddress.orderAddressId;
        }
        var obj = {
          coursePackageId: that.coursePackageId,
          coursePackagePriceId: that.coursePackagePriceId,
          isPointDeduction: isPointDeduction,
          payWay: payWay,
          couponId: that.couponIndex,
          orderAddressId: orderAddressId
        }
        course_pay(obj).then(res => {
          console.log(res)
          if (res.data.code == 0) {
            if (that.payTypeIndex == 1) {
              const div = document.createElement('div')//创建div
              div.innerHTML = res.data.data.aliSign
              document.body.appendChild(div)
              console.log(document.body)
              document.forms[0].submit();
            } else if (that.payTypeIndex == 2) {
              that.centerDialogVisible = true
              console.log(res.data.data.codeUrl)
              that.serviceId = res.data.data.serviceId
              setTimeout(() => {
                that.$QRCodeSC(res.data.data.codeUrl)
              }, 1000)
              that.order_status()
            } else if (that.payTypeIndex == 4) {
              window.location.href = res.data.data.url
              // that.$router.push({
              //     path:res.data.data.url,
              //     query:{}
              // })
            }
          }
        }).catch(error => {
          console.log(error)
        })
      } else {
        var payWay = ''
        if (that.payTypeIndex == '' || that.payTypeIndex == null || that.payTypeIndex == undefined) {
          that.$message.error('请选择支付方式');
          return false
        } else {
          if (that.payTypeIndex == 1) {
            payWay = '支付宝'
          } else if (that.payTypeIndex == 2) {
            payWay = '微信'
          } else if (that.payTypeIndex == 3) {
            payWay = '京东'
          } else if (that.payTypeIndex == 4) {
            payWay = '网银'
          }
        }
        var isIntegral = ''
        if (that.checkedDeduction == false) {
          isIntegral = 0
        } else {
          isIntegral = 1
        }
        var obj = {
          integralPrice: 0,
          couponPrice: that.couponObj.price,
          isIntegral: isIntegral,
          liveId: that.liveId,
          payPrice: that.amountPayable,
          payWay: payWay,
          couponId: that.couponIndex
        }
        //  var obj={
        //     coursePackageId:that.coursePackageId,
        //     coursePackagePriceId:that.coursePackagePriceId,
        //     isPointDeduction:isPointDeduction,
        //     payWay:payWay,
        //     couponId:that.couponIndex,
        //     orderAddressId:orderAddressId
        // }
        live_orderPay(obj).then(res => {
          console.log(res)
          if (that.payTypeIndex == 1) {
            const div = document.createElement('div')//创建div
            div.innerHTML = res.data.data.aliSign
            document.body.appendChild(div)
            console.log(document.body)
            document.forms[0].submit();
          } else if (that.payTypeIndex == 2) {
            that.centerDialogVisible = true
            console.log(res.data.data.codeUrl)
            that.serviceId = res.data.data.serviceId
            setTimeout(() => {
              that.$QRCodeSC(res.data.data.codeUrl)
            }, 1000)
            that.order_status()
            // this.$router.push({
            //     path: '/myself/myLive',
            //     query: {}
            // });

          } else if (that.payTypeIndex == 4) {
            window.location.href = res.data.data.url

            // that.$router.push({
            //     path:res.data.data.url,
            //     query:{}
            // })
          }
          console.log('支付成功')
        }).catch(error => {
          console.log(error)
        })
      }

    },
    // 获取订单状态
    order_status() {
      var obj = {
        serviceId: this.serviceId,
        serviceType: this.bayType,
      }
      order_status(obj).then(res => {
        if (res.data.data == 1) {
          this.$message.success('支付成功')
          this.centerDialogVisible = false;
          if (this.bayType == 1) {
            this.$router.push({
              path: '/coursedetail',
              query: { coursePackageId: this.coursePackageId }
            });
          } else if (this.bayType == 2) {
            this.$router.push({
              path: '/myself/myLive',
              query: {}
            });
          }



        } else {
          setTimeout(() => {
            this.order_status(obj)
          }, 2000)
        }
      }).catch(error => {
        console.log(error)
      })
    },

  }
}
</script>

<style>
</style>